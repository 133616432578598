.lang-switcher {
  background: #e9f6ff;
  cursor: pointer;
  font-weight: 500;
}
.lang-switcher-auth {
    background: none;
    cursor: pointer;
    font-weight: 500;
    position: absolute;
    left: 0;
    z-index: 999;
}

.summary-row {
  background: #dedddf;
}

.full-width {
  width: 100%;
}

.summary-line {
  background: lightgrey;
}

.main-tab-container {
  display: flex;
  border-bottom: 1px solid lightgray;
}

.main-tab-container div {
  padding: 1rem;
  box-sizing: border-box;
  cursor: pointer;
}

.main-tab-container div.item-selected {
  background: lightgray;
}

.general-label {
  padding: 1rem;
  box-sizing: border-box;
}

.general-label span {
  margin-right: 0.5rem;
}

.margin-right-5 {
  margin-right: 5px!important;
}

.btn-container {
  display: flex;
  justify-content: space-between;
}

.number-direction {
  display: inline-flex;
  direction: ltr;
}

.common-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightgrey;
  padding: 0.5rem;
  box-sizing: border-box;
}

.line-container {
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid lightgray;
}

.line-item {
  padding: 1rem;
  box-sizing: border-box;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  .subheader-item {
    font-weight: bold;
  }
  span.subheader-item {
    width: 20%;
  }
}


.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  max-width: 99%!important;
}

.value-direction {
  display: inline-block;
  direction: ltr!important;
}
