.payment-selection-form--selected {
  background: lightgrey;
  padding: 0.5rem;
  box-sizing: border-box;
}

.payment-selection-form {
  margin-top: 1rem;
  padding-top: 12px;
  border-top: 1px solid #DBDBDB;
  cursor: pointer;
  .payment-selection-form__option-selector {
    display: flex;
    justify-content: space-between;
  }

  .payment-selection-form__option {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 6px;

    width: calc(50% - 5px);
    height: 44px;
  }

  .payment-selection-form__option-text {
    font-size: 12px;
    margin: 0 10px;
    color: #3E4954;
  }
  .payment-selection-form__amount-input-wrapper {
    padding-top: 12px;
  }
  .payment-selection-form__amount-input.--error {
    border: 2px solid red;
  }
  .payment-selection-form__amount-input {
    text-align: right;
    background: #FFFFFF;
    border: 1px solid #6E1427;
    border-radius: 6px;
    padding: 0 20px;

    &::placeholder, &::-webkit-input-placeholder {
      text-align: right;
    }
  }

  .MuiRadio-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #6E1427;
  }
}

.add-card-button {
  display: block;
  margin: 10px 0;
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #bc1e46;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  border: 1px solid #bc1e46;
  border-radius: 10px;

  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.add-card-button:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 16px;
  right: 16px;
}

.--error {
  border: solid 1px #e24047;
}


//------------------------------------
.tranzila-wrapper {
  height: 400px;
}

.loader-container {
  top: 0;
  z-index: 1;
  position: absolute;
}
